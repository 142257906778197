// services/session.js

import axios from 'axios';

// const API_URL = 'http://localhost:4001/api/sessions'; 
// const API_URL = 'https://apiwsproject-latest.onrender.com/api/sessions'; // Ajusta esto según la configuración de tu backend

const API_URL = 'https://apiwsproject-latest-zu9g.onrender.com/api/sessions'; // Cambia la URL según tu configuración del backend

// Ajusta la URL a tu entorno (producción, dev, etc.)

const SessionService = {
  // Crea o actualiza la sesión con { userId, agentId }
  createSession: async (sessionData) => {
    try {
      // POST /api/sessions
      const response = await axios.post(API_URL, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Lista todas las sesiones
  getAllSessions: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Actualiza una sesión (p.ej. agentId, isAutonomous, etc.)
  updateSession: async (id, sessionData) => {
    try {
      const response = await axios.put(`${API_URL}/${id}`, sessionData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Elimina una sesión
  deleteSession: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};

export default SessionService;
