import axios from 'axios';

//const API_URL = "http://127.0.0.1:8000/api/v1/auth"
// const API_URL = 'http://localhost:4001/api/users'; // Cambia la URL según tu configuración del backend
// const API_URL = 'https://apiwsproject-latest.onrender.com/api/users'; // Ajusta esto según la configuración de tu backend

const API_URL = 'https://apiwsproject-latest-zu9g.onrender.com/api/users'; // Cambia la URL según tu configuración del backend

axios.defaults.withCredentials = true; 

const UserService = {
  // Método para login
  login: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/login`, { email, password });
      return response.data; // No necesitamos manejar el token aquí, se guarda como cookie
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Método para validar el email
  validateEmail: async (email) => {
    try {
      const response = await axios.post(`${API_URL}/validate-email`, { email });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  verifyCode: async (code) => {
    try {
      const response = await axios.post(`${API_URL}/verification/${code}`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Método para registrar un nuevo usuario
  register: async (email, password) => {
    try {
      const response = await axios.post(`${API_URL}/register`, {
        email,
        password,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Método para hacer logout
  logout: async () => {
    try {
      // Llamada al backend para limpiar la cookie del token
      await axios.post(`${API_URL}/logout`, null, {
        withCredentials: true, // Asegurarse de que las cookies se envíen con la solicitud
      });
    } catch (error) {
      console.error('Error en logout:', error);
    }
  },

  // Método para obtener los detalles del usuario autenticado
  getUserDetails: async () => {
    try {
      const response = await axios.get(`${API_URL}/me`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  saveApiKey: async (userId, apiKey) => {
    try {
      const response = await axios.post(`${API_URL}/save-api-key/${userId}`, { apiKey });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },
};


export default UserService;
