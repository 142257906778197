// src/components/ChatsTable.js
import React, { useEffect, useState, useCallback } from 'react';
import { ChatService } from '../services/chatService';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  CircularProgress,
  Typography,
  Alert
} from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PhoneIcon from '@mui/icons-material/Phone';
import { useGlobal } from '../context/globalContex';
import { useLocation } from 'react-router-dom';

const TablaSeguimiento = () => {
  const [chatsData, setChatsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);
  const [checking, setChecking] = useState(false);
  const { state } = useGlobal();
  const location = useLocation();
  const userId = location.state?.userId;

  const fetchUpdates = useCallback(async () => {
    if (!userId) return;

    try {
      setChecking(true);
      const { data, lastUpdate: newLastUpdate } = await ChatService.getChatUpdates(userId, lastUpdate);
      
      if (data) {
        setChatsData(data);
        setLastUpdate(newLastUpdate);
      }
      
      setError(null);
    } catch (err) {
      setError('Error al actualizar los chats');
      console.error('Error fetching updates:', err);
    } finally {
      setChecking(false);
    }
  }, [userId, lastUpdate]);

  const handleHideChat = useCallback(async (whatsappId) => {
    if (!userId) return;

    try {
      await ChatService.updateChatStatus(userId, whatsappId, true);
      fetchUpdates();
    } catch (err) {
      console.error('Error ocultando chat:', err);
      setError('Error al ocultar el chat');
    }
  }, [userId, fetchUpdates]);

  // Efecto para la carga inicial y comprobación periódica
  useEffect(() => {
    if (!userId) {
      setError('No se encontró ID de usuario');
      setLoading(false);
      return;
    }

    // Carga inicial completa
    const initialLoad = async () => {
      try {
        setLoading(true);
        const { data, lastUpdate: initialLastUpdate } = await ChatService.getChatUpdates(userId, null);
        if (data) {
          setChatsData(data);
          setLastUpdate(initialLastUpdate);
        }
        setError(null);
      } catch (err) {
        setError('Error al cargar los chats');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    initialLoad();

    // Configurar verificación periódica
    const intervalId = setInterval(fetchUpdates, 30000); // Verificar cada 30 segundos

    return () => clearInterval(intervalId);
  }, [userId, fetchUpdates]);

  const filteredChats = React.useMemo(() => 
    chatsData?.chats?.filter(chat => !chat.contactado) || [],
    [chatsData?.chats]
  );

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Mis Chats
      </Typography>
      
      {checking && (
        <Alert severity="info" sx={{ mb: 2 }}>
          Verificando actualizaciones...
        </Alert>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <CircularProgress />
        </div>
      ) : (
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Contacto</TableCell>
                <TableCell>Resumen de conversación</TableCell>
                <TableCell align="center">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredChats.length > 0 ? (
                filteredChats.map((chat) => (
                  <TableRow key={chat.whatsappId}>
                    <TableCell>{chat.fecha}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PhoneIcon />}
                        href={chat.whatsappLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Abrir seguimiento
                      </Button>
                    </TableCell>
                    <TableCell>{chat.resumen}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleHideChat(chat.whatsappId)}
                        color="warning"
                        title="Ocultar chat"
                      >
                        <VisibilityOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No hay chats disponibles
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default React.memo(TablaSeguimiento);