import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
  Divider,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';

import { sendValidatedResponse, deleteMessage } from '../services/client';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  transition: 'box-shadow 0.3s ease',
  position: 'relative',
  '&:hover': {
    boxShadow: theme.shadows[3],
  },
  '&.exiting': {
    opacity: 0,
    transform: 'scale(0.95)',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
  },
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 600,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5),
}));

const SectionContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  lineHeight: 1.6,
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '400px',
  maxHeight: '400px',
  width: 'auto',
  height: 'auto',
  marginTop: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

const ImageFull = styled('img')(() => ({
  maxWidth: '90vw',
  maxHeight: '90vh',
  width: 'auto',
  height: 'auto',
}));

const ResponseCard = ({ userId, response, onMessageDeleted }) => {
  const [editedResponse, setEditedResponse] = useState(response.gptResponse || '');
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [exiting, setExiting] = useState(false);
  const [openImage, setOpenImage] = useState(null);

  console.log('Response recibida en card:', {
    response,
    historyLength: response.summarizedHistory?.length,
    historyContent: response.summarizedHistory
  });

  const handleAction = async (action) => {
    setIsProcessing(true);
    try {
      switch (action) {
        case 'approve':
        case 'save':
          await sendValidatedResponse(userId, editedResponse, response.id);
          if (action === 'save') {
            setIsEditing(false);
          }
          break;

        case 'delete':
          setExiting(true);
          setTimeout(async () => {
            await deleteMessage(response.id);
            onMessageDeleted?.(response.id);
          }, 300);
          break;

        default:
          break;
      }
    } catch (error) {
      console.error(`Error processing ${action}:`, error);
    } finally {
      setIsProcessing(false);
    }
  };

  const renderUserResponse = () => {
    const ur = response.userResponse || {};
    const hasText = ur.text;
    const hasImage = ur.imageUrl;

    if (!hasText && !hasImage) {
      return <SectionContent>No user message available</SectionContent>;
    }
    return (
      <Box>
        {hasText && <SectionContent>{ur.text}</SectionContent>}
        {hasImage && (
          <ImagePreview
            src={ur.imageUrl}
            alt="user-img"
            onClick={() => setOpenImage(ur.imageUrl)}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      <StyledCard variant="outlined" className={exiting ? 'exiting' : ''}>
        <CardContent>
          {/* Sección del número de cliente */}
          <Section>
            <SectionTitle>Cliente</SectionTitle>
            <SectionContent sx={{ fontWeight: 'bold' }}>
              {response.phoneNumber || 'Número no disponible'}
            </SectionContent>
          </Section>

          <Divider sx={{ my: 2 }} />

          <Section>
            <SectionTitle>Conversation History</SectionTitle>
            {response.summarizedHistory && response.summarizedHistory.length > 0 ? (
              <Box sx={{ pl: 1 }}>
                {response.summarizedHistory.map((msg, idx) => (
                  <Box key={idx} sx={{ mb: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      {msg.role === 'assistant' ? 'Assistant:' : 'User:'}
                    </Typography>
                    <SectionContent sx={{ ml: 2 }}>{msg.text}</SectionContent>
                  </Box>
                ))}
              </Box>
            ) : (
              <SectionContent>No conversation history available</SectionContent>
            )}
          </Section>

          <Divider sx={{ my: 2 }} />

          <Section>
            <SectionTitle>User Message</SectionTitle>
            {renderUserResponse()}
          </Section>

          <Divider sx={{ my: 2 }} />

          <Section>
            <SectionTitle>GPT Response</SectionTitle>
            {isEditing ? (
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={editedResponse}
                onChange={(e) => setEditedResponse(e.target.value)}
                disabled={isProcessing}
                sx={{ mt: 1 }}
              />
            ) : (
              <SectionContent>{editedResponse}</SectionContent>
            )}
          </Section>

          <ActionButtons>
            {isEditing ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <CheckCircleIcon />
                  }
                  onClick={() => handleAction('save')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Saving...' : 'Save & Send'}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsEditing(false)}
                  disabled={isProcessing}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <CheckCircleIcon />
                  }
                  onClick={() => handleAction('approve')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Sending...' : 'Approve'}
                </Button>

                <IconButton
                  color="primary"
                  onClick={() => setIsEditing(true)}
                  disabled={isProcessing}
                >
                  <EditIcon />
                </IconButton>

                <Button
                  variant="contained"
                  color="error"
                  startIcon={
                    isProcessing ? <CircularProgress size={16} color="inherit" /> : <BlockIcon />
                  }
                  onClick={() => handleAction('delete')}
                  disabled={isProcessing}
                >
                  {isProcessing ? 'Deleting...' : 'Reject'}
                </Button>
              </>
            )}
          </ActionButtons>
        </CardContent>
      </StyledCard>

      <Dialog
        open={Boolean(openImage)}
        onClose={() => setOpenImage(null)}
        maxWidth="xl"
      >
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          {openImage && (
            <Box sx={{ textAlign: 'center' }}>
              <ImageFull src={openImage} alt="preview-large" />
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResponseCard;